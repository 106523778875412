import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import "./About.scss";
import AppWrap from "../../wrapper/AppWrapp";
import myself from "./myself.png";
import { client, urlFor } from "../../client";
import { useTranslation } from "react-i18next";

const About = () => {
  const [abouts, setAbouts] = useState([]);
  const { t, i18n } = useTranslation();
  useEffect(() => {
    const query = '*[_type == "abouts"]';
    client.fetch(query).then((data) => setAbouts(data));
  }, []);

  const isKurdish = i18n.language === "ku";
  return (
    <div className="about-container ">
      <h2 className={`head-text ${isKurdish ? "kurdish " : ""}`}>
        {t("about.title")} <span>{t("about.subtitle")}</span>
      </h2>
      <div className="about-content">
        <div className="app__aboutme">
          <div className="aboutme-content overflow-hidden">
            {isKurdish ? (
              <>
                <motion.p
                  whileInView={{ opacity: [0, 1], x: [200, 0] }}
                  transition={{ duration: 1.3 }}
                  className={`about-description ${
                    isKurdish ? "kurdish3 text-right" : " sm:text-left"
                  }`}
                >
                  {t("about.description")}
                </motion.p>
                <motion.img
                  whileInView={{ opacity: [0, 1], x: [-200, 0] }}
                  transition={{ duration: 1.3 }}
                  className="app__aboutme-img"
                  src={myself}
                  alt="Myself"
                />
              </>
            ) : (
              <>
                <motion.img
                  whileInView={{ opacity: [0, 1], x: [-200, 0] }}
                  transition={{ duration: 1.3 }}
                  className="app__aboutme-img"
                  src={myself}
                  alt="Myself"
                />
                <motion.p
                  whileInView={{ opacity: [0, 1], x: [200, 0] }}
                  transition={{ duration: 1.3 }}
                  className={`about-description ${
                    isKurdish ? "kurdish3 text-right" : " sm:text-left "
                  }`}
                >
                  {isKurdish && t("about.description")}
                  {!isKurdish &&
                    "Hello! I'm Shakar, a software engineering student at the American University of Iraq, Sulaimani. I'm also minoring in both management and artificial intelligence. I'm currently in my fifth semester, which is the third stage of my studies. Programming, graphic design, and content creation have been my childhood dreams, but I'm more focused on the field of programming and writing tech content. I'm a social person and always enjoy meeting new people, so I participate in many activities. My main goal is always to meet new people. I strongly believe in personal development, and in everything I do, my primary aim is learning and improvement. At the end of each task or project, I ask myself one question: How productive was I in this work? I have experience in web development, design, media work, content creation, and project management. If you want to get to know me better, you can follow me on my social media accounts and read my contents!"}
                </motion.p>
              </>
            )}
          </div>
        </div>
        <div className="app__profiles">
          {abouts.map((about, index) => (
            <motion.div
              key={about.title[i18n.language] + index}
              className="app__profile-item"
              whileHover={{ scale: 1.1 }}
              transition={{ duration: 0.5, type: "tween" }}
            >
              <motion.div
                whileInView={{ opacity: [0, 1] }}
                transition={{ duration: 1.7 }}
              >
                <motion.img src={urlFor(about.imgUrl)} alt="about" />
                <motion.h2
                  className={`bold-text ${
                    isKurdish ? "kurdish text-right" : "text-left"
                  }`}
                >
                  {about.title[i18n.language]}
                </motion.h2>
                <motion.p
                  className={`des p-text ${
                    isKurdish ? "kurdish3 text-right" : "text-left"
                  }`}
                >
                  {about.description[i18n.language]}
                </motion.p>
              </motion.div>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AppWrap(About, "about");
